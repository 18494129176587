@import 'variables';
@import 'actiontext';
@import 'icheck';
@import '~blueimp-file-upload/css/jquery.fileupload';
@import 'fileupload';
@import '~font-awesome/scss/font-awesome';
@import '~bootstrap-sass/assets/stylesheets/_bootstrap';
@import '~datatables.net-bs/css/dataTables.bootstrap';
@import '~select2/dist/css/select2';
@import '~admin-lte/dist/css/AdminLTE';
@import '~admin-lte/dist/css/skins/skin-black';

body { height: 100vh; }

table.table tbody td { vertical-align: middle !important; }

textarea.textile_editor { font-family: 'Roboto Mono', monospace; margin-top: 5px; }

form label { cursor: pointer; }

form label.boolean div,
form .radio label div,
form .checkbox label div { margin-right: 5px; }

form .radio label, form .checkbox label { padding-left: 0px; }

.field_with_errors {
  select, input, textarea { border-color: #600; }
}


.select2-container { width: 100% !important; }

table.table-unbordered {
  border: none;

  tr th, tr td { border: none; }
}

.navbar-brand { padding: 0px 15px; }

table.table tbody tr.nested-fields td { vertical-align: top !important; }
